import moment from 'moment';
import * as db from './db';
import {
    average
} from './utils';

export const store = async function (table_name, index, value, measurements) {
    let table = db.table(table_name);
    table.get(index, value).then(measure => {
        for (let key of db.sensors) {
            measure[key] = average(measurements.map(e => e[key]), 2);
        }
        measure.save();
    }).catch(() => {
        let data = {
            index: value,
            date: moment().format('YYYY-MM-DD[T]HH:mm:ss'),
        }
        for (let key of db.sensors) {
            data[key] = average(measurements.map(e => e[key]), 2);
        }
        table.insert(data);
    });
}