<template>
    <div class="modal fade" id="configInputModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
        aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="staticBackdropLabel"><i class="fa fa-cog"></i></h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div v-if="step == 0">
                        <h3 class="text-center" style="font-size: 70px;"><i class="fa fa-cog"></i></h3>
                        <p class="text-center">{{ lang('messages.import_config_file') }}</p>
                        <input type="file" accept="application/json" @change="readFile" class="form-control">
                    </div>
                    <div v-else-if="step == 1">
                        <h3 class="text-center" style="font-size: 70px;"><i class="fa fa-question-circle"></i></h3>
                        <p class="text-center">{{ lang('messages.import_config_file_confirm') }}</p>
                    </div>
                    <div v-else-if="step == 2">
                        <h3 class="text-center" style="font-size: 70px;"><i class="fa fa-cog a-rotate"></i></h3>
                        <p class="text-center a-dotted">{{ lang('messages.import_config_file_uploading') }}
                        </p>
                    </div>
                    <div v-else-if="step == 3">
                        <h3 class="text-center text-success" style="font-size: 70px;"><i class="fa fa-check-circle"></i>
                        </h3>
                        <p class="text-center">{{ lang('messages.import_config_file_success') }}</p>
                    </div>
                    <div v-else-if="step == 4">
                        <h3 class="text-center text-danger" style="font-size: 70px;"><i class="fa fa-warning"></i></h3>
                        <p class="text-center">{{ lang('errors.import_config_file_error') }}</p>
                    </div>
                </div>
                <div class="modal-footer">
                    <div v-if="step == 0">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i
                                class="fa fa-times"></i>
                            {{ lang('buttons.cancel') }}</button>
                        <button type="button" class="btn btn-primary" :disabled="content == ''" @click="next"><i
                                class="fa fa-arrow-right"></i> {{ lang('buttons.continue') }}</button>
                    </div>
                    <div v-if="step == 1">
                        <button type="button" class="btn btn-secondary" @click="prev"><i class="fa fa-arrow-left"></i>
                            {{ lang('buttons.back') }}</button>
                        <button type="button" class="btn btn-primary" @click="uploadConfig"><i class="fa fa-check"></i>
                            {{ lang('buttons.confirm') }}</button>
                    </div>
                    <div v-else-if="step == 3">
                        <!-- succeed -->
                        <button type="button" class="btn btn-success" data-bs-dismiss="modal"
                            @click="localSetup">{{ lang('buttons.confirm') }}</button>
                    </div>
                    <div v-else-if="step == 4">
                        <!-- error -->
                        <button type="button" class="btn btn-danger" data-bs-dismiss="modal"
                            @click="localSetup">{{ lang('buttons.confirm') }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import * as lang from '@/assets/js/lang';
    import * as ws from '@/assets/js/socket';
    export default {
        data() {
            return {
                content: '',
                filename: '',
                step: 0
            }
        },
        methods: {
            lang(key) {
                return lang.getTranslation(key);
            },
            localSetup() {
                this.$store.state.ready = false;
                setTimeout(() => {
                    this.$store.state.ready = true;
                    this.$store.state.isAuthenticated = false;
                }, 2000);
            },
            next() {
                if (this.step < 4) {
                    this.step++;
                }
            },
            prev() {
                if (this.step > 0) {
                    this.step--;
                }
            },
            readFile: async function (ev) {
                if (ev.target.files.length != 1) {
                    this.step = 4;
                }
                let file = ev.target.files[0];
                console.log(file);
                let reader = file => new Promise((resolve, reject) => {
                    let fr = new FileReader();
                    fr.onload = function (e) {
                        resolve(e.target.result);
                        return e.target.result;
                    }
                    fr.onerror = reject;
                    // fr.readAsArrayBuffer(file);
                    fr.readAsText(file);
                });
                let config = await reader(file);
                config = JSON.parse(config);
                this.content = config;
                this.filename = file.name;
            },
            uploadConfig() {
                ws.post('/system/config', {
                    config: this.content
                });
            }
        },
        mounted() {
            let self = this;
            ws.receive({
                endpoint: '/system/config',
                method: 1,
                success: (data) => {
                    if (data.method == 1) {
                        self.step = 2;
                        setTimeout(() => {
                            self.step = 3;
                        }, 3000);
                    }
                }
            });
            this.$nextTick((e) => {
                let el = this.$el;
                el.addEventListener('show.bs.modal', (e) => {
                    self.step = 0;
                });
            });

        },
    }
</script>