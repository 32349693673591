import * as cookie from './cookie';


var ws = null;
var listeners = {};
var errorListeners = {};
var middlewares = [];
var lastRequest = null;
var reconnectInterval = null;
var connectionHost = null;
var connectionTimeout = null;

export const receive = (obj, fn) => {
    let method = 0;
    if (fn !== undefined) {
        if (!Object.keys(listeners).includes(obj)) {
            listeners[obj] = {
                0: null,
                1: null
            }
        }
        listeners[obj][method] = fn;
    } else {
        method = obj.method;
        if (!Object.keys(listeners).includes(obj.endpoint)) {
            listeners[obj.endpoint] = {
                0: null,
                1: null
            }
        }
        listeners[obj.endpoint][method] = obj.success;

        if (!Object.keys(errorListeners).includes(obj.endpoint)) {
            errorListeners[obj.endpoint] = {
                0: null,
                1: null
            }
        }
        errorListeners[obj.endpoint][method] = obj.error;
    }
}
export const middleware = (fn) => {
    middlewares.push(fn);
}

export const connect = (host) => {
    if (ws != null) {
        ws.onclose = null;
        ws.onmessage = null;
        ws.onerror = null;
        ws.onopen = null;
    }
    ws = new WebSocket(host);
    connectionHost = host;
    ws.onerror = (event) => {
        const evt = new CustomEvent('ws.error', {
            detail: event
        });
        window.dispatchEvent(evt);
    }
    ws.onopen = (event) => {
        const evt = new CustomEvent('ws.open', {
            detail: event
        });
        if (lastRequest != null) {
            ws.send(lastRequest);
            lastRequest = null;
        }
        window.dispatchEvent(evt);
    }
    ws.onclose = (event) => {
        const evt = new CustomEvent('ws.close', {
            detail: event
        });
        reconnect();
        window.dispatchEvent(evt);
    }
    ws.onmessage = (event) => {
        let data = event.data;
        try {
            data = JSON.parse(event.data);
        } catch (e) {
            data = event.data;
        }

        // console.log('income message: ',data);

        if (data == 'pong') {
            clearTimeout(connectionTimeout);
            connectionTimeout = null;
            return true;
        }

        let pass = true;
        if (middlewares.length > 0) {
            for (let middle of middlewares) {
                if (!middle(data)) {
                    pass = false;
                }
            }
        }
        let method = data.method;
        if (pass) {
            for (let endpoint in listeners) {
                if (endpoint == data.endpoint) {
                    if (method !== undefined) {
                        try {
                            listeners[endpoint][method](data)
                        } catch (e) {
                            console.warn('endpoint: %s or method: %s not regitered. Trying with method %s', endpoint, method, method == 1 ? 0 : 1)
                            try {
                                listeners[endpoint][method == 1 ? 0 : 1](data)
                            } catch (e) {
                                console.error('no method registered for %s', endpoint)
                            }
                        }
                    } else {
                        if (listeners[endpoint]) {
                            if (listeners[endpoint][0]) {
                                listeners[endpoint][0](data);
                            }
                            if (listeners[endpoint][1]) {
                                listeners[endpoint][1](data);
                            }
                        }
                    }

                }
            }
        } else {
            for (let endpoint in errorListeners) {
                if (endpoint == data.endpoint) {
                    if (method !== undefined) {
                        try {
                            errorListeners[endpoint][method](data)
                        } catch (e) {
                            console.warn('endpoint: %s or method: %s not regitered. Trying with method %s', endpoint, method, method == 1 ? 0 : 1)
                            try {
                                errorListeners[endpoint][method == 1 ? 0 : 1](data)
                            } catch (e) {
                                console.error('no method registered for %s', endpoint)
                            }
                        }
                    } else {
                        if (errorListeners[endpoint]) {
                            if (errorListeners[endpoint][0]) {
                                errorListeners[endpoint][0](data);
                            }
                            if (errorListeners[endpoint][1]) {
                                errorListeners[endpoint][1](data);
                            }
                        }
                    }

                }
            }
            console.error('Middleware deny the request %s', data.endpoint);
            console.error('Denied request payload ', data);
        }
        const evt = new CustomEvent('ws.message', {
            detail: data
        });
        window.dispatchEvent(evt);
    }
}
export const reconnect = () => {
    if (reconnectInterval == null) {
        reconnectInterval = setInterval(() => {
            if (ws.readyState == WebSocket.OPEN) {
                clearInterval(reconnectInterval);
                const evt = new CustomEvent('ws.reconnect');
                window.dispatchEvent(evt);
                reconnectInterval = null;
            } else if (ws.readyState != WebSocket.CONNECTING) {
                connect(connectionHost);
            }
        }, 5000);
    }
}

export const get = (url) => {
    let token = cookie.get('token');
    return send({
        endpoint: url,
        method: 'GET',
        token: token
    })
}
export const post = (url, data) => {
    let token = cookie.get('token');
    return send({
        endpoint: url,
        method: 'POST',
        token: token,
        content: data
    })
}

export const send = (payload) => {
    let token = cookie.get('token');
    if (typeof (payload) == 'object') {
        if (!Object.keys(payload).includes('token') && !Object.keys(payload).includes('totp')) {
            payload.token = token;
        }
        if (payload.content) {
            for (let k in payload.content) {
                let v = payload.content[k];
                if (typeof (v) == 'boolean') {
                    payload.content[k] = v ? 1 : 0;
                }
            }
        }
        if (payload.method && typeof (payload.method) == 'string') {
            payload.method = payload.method.toUpperCase() == 'GET' ? 0 : 1;
        }
        // do more data convert here...
    }
    if (typeof (payload) == 'object') {
        payload = JSON.stringify(payload);
    }
    const evt = new CustomEvent('ws.error', {
        detail: 'connection lost'
    });
    // console.log('outcome message: ',payload);
    try {
        ws.send(payload);
        if (payload == 'ping' && connectionTimeout == null) {
            connectionTimeout = setTimeout(() => {
                ws.close();
                window.dispatchEvent(evt);
            }, 5000);
        }
    } catch (e) {
        console.log(e);
        window.dispatchEvent(evt);
        lastRequest = payload;
    }
}