<template>
    <div v-if="!ready" class="loading">
        <div class="indicator">
        </div>
    </div>
</template>
<script>
    import {mapState} from 'vuex';
    export default {
        name: 'loading',
        computed:{
            ...mapState(['ready'])
        }
    }
</script>