<template>
    <div class="modal fade" id="exportModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
        aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title text-dark" id="staticBackdropLabel"><i class="fa fa-download"></i></h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body d-flex align-items-center justify-content-around">
                    <a href="javascript:;" @click="saveReport('csv')" class="p-3"><img src="@/assets/img/csv.png" alt=""
                            class="export-icon"></a>
                    <a href="javascript:;" @click="saveReport('json')" class="p-3"><img src="@/assets/img/json.png"
                            alt="" class="export-icon"></a>
                    <a href="javascript:;" @click="saveReport('tsv')" class="p-3"><img src="@/assets/img/tsv.png" alt=""
                            class="export-icon"></a>
                    <a href="javascript:;" @click="saveReport('xlsx')" class="p-3"><img src="@/assets/img/xlsx.png"
                            alt="" class="export-icon"></a>
                </div>
                <div class="modal-footer">
                    <div class="d-flex w-100 justify-content-between align-items-center">
                        <button type="button" class="btn btn-devward"
                            data-bs-dismiss="modal">{{ lang('buttons.accept') }}</button>
                        <div v-if="state == 1" class="a-dotted">
                            {{ lang('messages.report_generating') }}</div>
                        <div v-else-if="state == 2" class="text-succcess">
                            {{ lang('messages.report_generated') }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import * as lang from '@/assets/js/lang';
    import * as report from '@/assets/js/report';
    import moment from 'moment';

    import {
        sensors
    } from '@/assets/js/db';
    import {
        mapState
    } from 'vuex';

    export default {
        name: 'exportpopup',
        data() {
            return {
                state: 0
            }
        },
        computed: {
            ...mapState(['reportData']),
            data() {
                return this.reportData;
            }
        },
        methods: {
            lang(key) {
                return lang.getTranslation(key);
            },
            getDataForReport: function (format) {
                return new Promise((resolve, reject) => {
                    let data = [];
                    if (format == 'json') { // build especial data for JSON report format
                        let date_key = this.lang('labels.date');
                        let time_key = this.lang('labels.time_no_utc');
                        this.data.forEach(e => {

                            let row = {}
                            row[date_key] = e.date.split('T')[0];
                            row[time_key] = e.date.split('T')[1];
                            for (let key of sensors) {
                                let sensor_key = this.lang(`sensors_sn.${key}`);
                                row[sensor_key] = e[key];
                            }
                            data.push(row);
                        });
                    } else { // build 2D generic array of data for {format} report format
                        let headers = [
                            this.lang('labels.date'),
                            this.lang('labels.time_no_utc')
                        ];
                        for (let key of sensors) {
                            headers.push(this.lang(`sensors_sn.${key}`));
                        }
                        data = [headers];
                        this.data.forEach(e => {
                            let date = e.date.split('T')[0]
                            let time = e.date.split('T')[1]
                            let row = [date, time];
                            for (let key of sensors) {
                                row.push(e[key]);
                            }
                            data.push(row);
                        });
                    }
                    resolve(data);
                    return data;
                })
            },
            saveReport: async function (format) {
                this.state = 1;
                let data = await this.getDataForReport(format);
                report.generate(data, format, moment().format('YYYY-MM-DD hh.mm.ss'));
                this.state = 2;
                setTimeout(() => {
                    this.state = 0;
                }, 3000);
            },
        }
    }
</script>