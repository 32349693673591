import {
  createRouter,
  createWebHashHistory
} from 'vue-router'

const routes = [{
    path: '/',
    name: 'info',
    component: () => import( /* webpackChunkName: "yukon" */ '../views/SystemInfo.vue')
  },
  {
    path: '/wifi',
    name: 'wifi',
    component: () => import( /* webpackChunkName: "yukon" */ '../views/Wifi.vue')
  },
  {
    path: '/hvac',
    name: 'hvac',
    component: () => import( /* webpackChunkName: "yukon" */ '../views/Hvac.vue')
  },
  {
    path: '/modbus',
    name: 'modbus',
    component: () => import( /* webpackChunkName: "yukon" */ '../views/Modbus.vue')
  },
  {
    path: '/statistics',
    name: 'statistics',
    component: () => import( /* webpackChunkName: "yukon" */ '../views/Statistics.vue')
  },
  {
    path: '/statistics/overview',
    name: 'overview',
    component: () => import( /* webpackChunkName: "yukon" */ '../views/Overview.vue')
  },
  {
    path: '/sensors',
    name: 'sensors_general',
    component: () => import( /* webpackChunkName: "yukon" */ '../views/sensors/General.vue')
  },
  {
    path: '/sensors/config',
    name: 'sensors_config',
    component: () => import( /* webpackChunkName: "yukon" */ '../views/sensors/Config.vue')
  },
  {
    path: '/sensors/calibration',
    name: 'sensors_calibration',
    component: () => import( /* webpackChunkName: "yukon" */ '../views/sensors/Calibration.vue')
  },
  {
    path: '/system',
    name: 'system_general',
    component: () => import( /* webpackChunkName: "yukon" */ '../views/system/General.vue')
  },
  {
    path: '/system/diagnostic',
    name: 'system_diagnostic',
    component: () => import( /* webpackChunkName: "yukon" */ '../views/system/Diagnostic.vue')
  },
  {
    path: '/system/date',
    name: 'system_clock',
    component: () => import( /* webpackChunkName: "yukon" */ '../views/system/DateAndTime.vue')
  },
  {
    path: '/system/language',
    name: 'system_lang',
    component: () => import( /* webpackChunkName: "yukon" */ '../views/system/Language.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})


export default router