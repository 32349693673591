import {
    createStore
} from 'vuex';



export default createStore({
    state: {
        isAuthenticated: false, // set to false to real websocket connection validation
        ready: false,
        showSidebar: true,
        host: '192.168.1.2',
        reportData: [],
        ota_update: 0,
        version: process.env.VUE_APP_VERSION,
        message: {
            active: false,
            content: '',
            level: 'secondary',
            animation: null,
            duration: 3000,
            icon: '',
        },
        overview: {
            sensors: [],
            aqi_general: -1
        },
        measurements: [],
        sensors_units: {}
    },
    mutations: {
        toggleSidebar(state) {
            state.showSidebar = !state.showSidebar;
        },
        hideNotification(state) {
            state.message.active = false;
        },
        readyState(state, value) {
            state.ready = value;
        },
        authState(state, value) {
            state.isAuthenticated = value;
        },
        storeMeasure(state, measure) {
            state.measurements.push(measure);
        },
        storeSensorUnit(state, {
            key,
            unit
        }) {
            state.sensors_units[key] = unit;
            window.localStorage.setItem('sensors_units', JSON.stringify(state.sensors_units) || {});
        },
        setRemoteHost(state, host) {
            state.host = host;
        },
        loadSensorsUnits(state) {

            try {
                let units = JSON.parse(localStorage.getItem('sensors_units'));
                Object.assign(state.sensors_units, units || {});
            } catch (e) {
                throw new Error('Cant find stored sensors units ' + e);
            }
        }
    },
    actions: {},
    modules: {}
})