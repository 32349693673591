export const sensors = ['co2', 'temp', 'hum', 'no2', 'co', 'nh3', 'tvoc', 'pm0_3', 'pm0_5', 'pm1_0', 'pm2_5', 'pm10', 'c2h5oh', 'h2', 'ch4', 'c3h8', 'c4h10'];
var store = null;
var migrations = [];
var seeders = [];
export var offset = 0;

export const migrate = () => {
    if (store != null) {
        if (migrations.length > 0) {
            for (let migration of migrations) {
                let arr = window.Array.from(store.objectStoreNames);
                if (arr.includes(migration.name)) {
                    store.deleteObjectStore(migration.name);
                }

                let os = store.createObjectStore(migration.name, {
                    keyPath: 'id',
                    autoIncrement: true
                });
                for (let field of migration.fields) {
                    let name = field.split('|')[0];
                    os.createIndex(name, name, {
                        unique: field.indexOf('unique') > -1
                    })
                }
                if (seeders.length > 0) {
                    for (let seeder of seeders) {
                        if (seeder.table == migration.name) {
                            for (let seed of seeder.seeds) {
                                os.put(seed);
                            }
                        }
                    }
                }
            }
        }
    }
}

export const init = (options) => new Promise((resolve, reject) => {
    if (!window.indexedDB) {
        console.error("IndexedDB not supported!");
    }
    let name = options.name || 'devward_db';
    migrations = options.tables || [];
    seeders = options.seeders || [];

    let version = parseInt(localStorage.getItem('dbVersion')) || 1;
    let tables = localStorage.getItem('dbMigrations') || null;
    if (tables != null && tables != JSON.stringify(migrations)) {
        version++;
    }

    localStorage.setItem('dbMigrations', JSON.stringify(migrations));
    localStorage.setItem('dbVersion', version);
    let request = window.indexedDB.open(name, version);
    request.onerror = (e) => {
        const evt = new CustomEvent('db.error', {
            detail: e
        });
        window.dispatchEvent(evt);
        reject(e);
    }
    request.onupgradeneeded = function (e) {
        store = e.target.result;
        const evt = new CustomEvent('db.upgrade', {
            detail: store
        });
        window.dispatchEvent(evt);
        migrate();
        resolve(store);
    };
    request.onsuccess = (e) => {
        store = request.result;
        const evt = new CustomEvent('db.load', {
            detail: store
        });
        window.dispatchEvent(evt);
        resolve(store);
    }
});

export const drop = (name) => {
        window.indexedDB.deleteDatabase(name || store.name);
        localStorage.removeItem('dbVersion');
        localStorage.removeItem('dbMigrations');
        window.location.reload();
}

export const table = (name, mode = 'readwrite') => {
    return {
        get: (column, value) => new Promise((resolve, reject) => {
            let table = store.transaction([name], mode).objectStore(name);
            let index = table.index(column);
            let request = index.get(value);
            request.onsuccess = (e) => {
                if (e.target.result) {
                    resolve({
                        ...e.target.result,
                        delete: function () {
                            table.delete(this.id);
                        },
                        update(copy) {
                            Object.assign(this, copy);
                            this.save();
                        },
                        save: function () {
                            let clone = JSON.parse(JSON.stringify(this));
                            delete clone.save;
                            delete clone.delete;
                            delete clone.update;
                            table.put(clone);
                        }
                    })
                }
                reject(`Index ${value} not found`);
            }
            request.onerror = reject;
        }),
        where: (column, value) => new Promise((resolve, reject) => {
            let table = store.transaction([name], mode).objectStore(name);
            let index = table.index(column);
            let range = IDBKeyRange.only(value);
            let cursor = index.openCursor(range);
            let data = [];
            cursor.onsuccess = (e) => {
                cursor = e.target.result;
                if (cursor) {
                    data.push({
                        ...cursor.value,
                        delete: function () {
                            table.delete(this.id);
                        },
                        update(copy) {
                            Object.assign(this, copy);
                            this.save();
                        },
                        save: function () {
                            let clone = JSON.parse(JSON.stringify(this));
                            delete clone.save;
                            delete clone.delete;
                            delete clone.update;
                            table.put(clone);
                        }
                    });
                    cursor.continue();
                } else {
                    resolve(data);
                }

            }
            cursor.onerror = reject;

        }),
        getOrCreate: async function (column, value) {
            let table = store.transaction([name], mode).objectStore(name);
            let exists = await this.where(column, value);
            if (exists.length > 0) {
                return exists;
            }

            let keys = Array.from(table.indexNames);
            let newInstance = {
                update(copy) {
                    Object.assign(this, copy);
                    this.save();
                },
                save: function () {
                    let clone = JSON.parse(JSON.stringify(this));
                    delete clone.save;
                    delete clone.delete;
                    delete clone.update;
                    table.put(clone);
                }
            }
            for (let k of keys) {
                newInstance[k] = undefined;
            }
            return newInstance;
        },
        getRange: (orderBy = 'id', range = null) => new Promise((resolve, reject) => {
            let table = store.transaction([name], mode).objectStore(name);
            let index = table.index(orderBy);
            let cursor = index.openCursor(range);
            let data = [];
            cursor.onsuccess = (e) => {
                cursor = e.target.result;
                if (cursor) {
                    data.push({
                        ...cursor.value,
                        delete: function () {
                            table.delete(this.id)
                        },
                        update(copy) {
                            Object.assign(this, copy);
                            this.save();
                        },
                        save: function () {
                            let clone = JSON.parse(JSON.stringify(this));
                            delete clone.save;
                            delete clone.delete;
                            delete clone.update;
                            table.put(clone);
                        }
                    });
                    cursor.continue();
                } else {
                    resolve(data);
                }

            }
            cursor.onerror = reject;
        }),
        all: () => new Promise((resolve, reject) => {
            let table = store.transaction([name], mode).objectStore(name);
            let cursor = table.openCursor(null);
            let data = [];
            cursor.onsuccess = (e) => {
                cursor = e.target.result;
                if (cursor) {
                    data.push({
                        ...cursor.value,
                        delete: function () {
                            table.delete(this.id)
                        },
                        update(copy) {
                            Object.assign(this, copy);
                            this.save();
                        },
                        save: function () {
                            let clone = JSON.parse(JSON.stringify(this));
                            delete clone.save;
                            delete clone.delete;
                            delete clone.update;
                            table.put(clone);
                        }
                    });
                    cursor.continue();
                } else {
                    resolve(data);
                }

            }
            cursor.onerror = reject;

        }),
        insert: (values) => {
            let table = store.transaction([name], mode).objectStore(name);
            table.add(values);
        },
        drop: () => {
            store.deleteObjectStore(name);
        },
        paginate: ({
            page,
            range,
            size
        }) => new Promise((resolve, reject) => {
            let table = store.transaction([name], mode).objectStore(name);
            let data = [];
            let paginated = false;
            let cursor = table.openCursor(range, direction);
            cursor.onsuccess = (e) => {
                cursor = e.target.result;
                if (!paginated && page > 0) {
                    cursor.advance(offset + page * size);
                    paginated = true;
                    return;
                }
                if (cursor) {
                    data.push(cursor.value);
                    if (data.length < size) {
                        cursor.continue();
                    } else {
                        data = data.reverse();
                        resolve(data);
                        return data;
                    }
                } else {
                    data = data.reverse();
                    resolve(data);
                    return data;
                }
            }
            cursor.onerror = reject;
        })
    }
}