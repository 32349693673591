import * as XLSX from 'xlsx';
import * as lang from './lang';

import {
    string2ArrayBuffer
} from './utils';

var format = '';
var blob = null;

var name = 'Yukon Report';
export const tsv = (data) => {
    let text = '';
    data.forEach((e, i) => {
        for (let value of e) {
            if (typeof (value) == 'number') {
                value = value.toString();
                if (value.indexOf('.') > -1) {
                    value = '"' + value.replace('.', ',') + '"';
                }
            }
            text += value + '\t';
        }
        text += '\n';
    });
    blob = new Blob([string2ArrayBuffer(text)], {
        type: "application/octet-stream"
    })
    format = '.tsv';
    download()
    return blob;
}
export const csv = (data) => {
    let text = '';
    data.forEach((e, i) => {
        for (let value of e) {
            if (typeof (value) == 'number') {
                value = value.toString();
                if (value.indexOf('.') > -1) {
                    value = '"' + value.replace('.', ',') + '"';
                }
            }
            text += value + ',';
        }
        text += '\n';
    });
    blob = new Blob([string2ArrayBuffer(text)], {
        type: "application/octet-stream"
    })
    format = '.csv';
    download()
    return blob;
}
export const xlsx = (data) => {
    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, name);

    const output = XLSX.write(wb, {
        bookType: 'xlsx',
        type: 'binary',
        compression: true
    });
    blob = new Blob([string2ArrayBuffer(output)], {
        type: "application/octet-stream"
    });
    format = '.xlsx';
    download();
    return blob;
}
export const json = (data) => {
    let obj = {};
    let key = lang.getTranslation('headers.measurements');
    obj[key] = data;

    let text = JSON.stringify(obj);
    blob = new Blob([string2ArrayBuffer(text)], {
        type: "application/octet-stream"
    });
    format = '.json';
    download();
    return blob;
}
export const generate = (data, exportFormat = 'csv',filename='Yukon Report') => {
    name = filename || 'Report';
    switch (exportFormat) {
        case 'csv':
            return csv(data);
        case 'tsv':
            return tsv(data);
        case 'json':
            return json(data);
        case 'xlsx':
            return xlsx(data);
        default:
            throw new Error('Format %s not supported!', exportFormat);
    }
}
export const download = () => {
    try {
        if (!blob) {
            throw 'Blob is null';
        }
        const filename = (name || 'Report') + format;
        // Support for IE.
        if (window.navigator.msSaveBlob) {
            window.navigator.msSaveBlob(blob, filename);
            return false;
        } else {
            const a = document.createElement('a');
            a.href = URL.createObjectURL(blob);
            a.download = filename;
            a.addEventListener('click', (e) => {
                setTimeout(() => {
                    URL.revokeObjectURL(a.href);
                }, 10000);
            })
            a.click();
        }
    } catch (e) {
        throw new Error('Error converting to ' + format + e);
    }
}