<template>
    <div id="sidebar" :class="['sidebar', showSidebar ? 'active' : '']">
        <div class="sidebar-wrapper">
            <div class="sidebar-header">
                <img
                    src="@/assets/img/icon.png"
                    alt="logo.png"
                    class="sidebar-logo"
                />
            </div>

            <ul class="nav flex-column list-unstyled pb-3">
                <li>
                    <p class="m-0">{{ lang("sidebar.configuration") }}</p>
                </li>
                <li>
                    <a
                        href="#conn_menu"
                        class="dropdown-toggle"
                        data-bs-toggle="collapse"
                        >{{ lang("sidebar.conn") }}</a
                    >
                    <ul class="list-unstyled collapse" id="conn_menu">
                        <li>
                            <router-link class="tab-toggler" to="/wifi"
                                >Wi-Fi</router-link
                            >
                        </li>
                        <li>
                            <router-link class="tab-toggler" to="/modbus"
                                >Modbus</router-link
                            >
                        </li>
                    </ul>
                </li>
                <li>
                    <a
                        href="#sensors_menu"
                        class="dropdown-toggle"
                        data-bs-toggle="collapse"
                        >{{ lang("sidebar.sensors.title") }}</a
                    >
                    <ul class="list-unstyled collapse" id="sensors_menu">
                        <li>
                            <router-link class="tab-toggler" to="/sensors/"
                                >{{ lang("sidebar.sensors.general") }}
                            </router-link>
                        </li>
                        <li>
                            <router-link
                                class="tab-toggler"
                                to="/sensors/config"
                                >{{ lang("sidebar.sensors.config") }}
                            </router-link>
                        </li>
                        <li>
                            <router-link
                                class="tab-toggler"
                                to="/sensors/calibration"
                            >
                                {{ lang("sidebar.sensors.calibration") }}
                            </router-link>
                        </li>
                    </ul>
                </li>
                <li>
                    <router-link class="tab-toggler" to="/hvac">
                        HVAC
                    </router-link>
                </li>
                <li>
                    <p class="m-0">{{ lang("sidebar.statistics") }}</p>
                </li>
                <li>
                    <a
                        href="#statistics_menu"
                        class="dropdown-toggle"
                        data-bs-toggle="collapse"
                        >{{ lang("sidebar.statistics") }}</a
                    >
                    <ul class="list-unstyled collapse" id="statistics_menu">
                        <li>
                            <router-link
                                class="tab-toggler"
                                to="/statistics/overview"
                            >
                                {{ lang("sidebar.statistics_overview") }}
                            </router-link>
                        </li>
                        <li>
                            <router-link class="tab-toggler" to="/statistics"
                                >{{ lang("sidebar.statistics_sensors") }}
                            </router-link>
                        </li>
                    </ul>
                </li>
                <li>
                    <p class="m-0">{{ lang("sidebar.system") }}</p>
                </li>
                <li>
                    <a
                        href="#system_menu"
                        class="dropdown-toggle"
                        data-bs-toggle="collapse"
                        >{{ lang("sidebar.system") }}</a
                    >
                    <ul class="list-unstyled collapse" id="system_menu">
                        <li>
                            <router-link class="tab-toggler" to="/system"
                                >{{ lang("sidebar.configuration") }}
                            </router-link>
                        </li>
                        <li>
                            <router-link
                                class="tab-toggler"
                                to="/system/diagnostic"
                                >{{ lang("sidebar.diagnostic") }}
                            </router-link>
                        </li>
                        <li>
                            <router-link class="tab-toggler" to="/system/date"
                                >{{ lang("sidebar.date_and_time") }}
                            </router-link>
                        </li>
                    </ul>
                </li>
                <li>
                    <router-link class="tab-toggler active" to="/">{{
                        lang("sidebar.info")
                    }}</router-link>
                </li>
                <li>
                    <a
                        class="tab-toggler"
                        href="javascript:;"
                        @click="logout"
                        >{{ lang("sidebar.logout") }}</a
                    >
                </li>
                <li class="mt-5 text-center mb-1">
                    <button
                        type="button"
                        data-bs-target="#configInputModal"
                        data-bs-toggle="modal"
                        class="btn btn-sm btn-outline-secondary"
                    >
                        {{ lang("buttons.import_config") }}
                    </button>
                </li>
                <li class="text-center mb-3">
                    <button
                        type="button"
                        @click="exportConfig"
                        class="btn btn-sm btn-secondary"
                    >
                        {{ lang("buttons.export_config") }}
                    </button>
                </li>
                <li
                    v-if="ota_update == 2 || ota_update == 5"
                    class="text-center"
                >
                    <button
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#updateModal"
                        class="btn btn-primary"
                        style="font-size: 12px"
                    >
                        <i class="fa fa-info-circle"></i>
                        {{ lang("sidebar.new_update") }}
                    </button>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    import { mapState, mapMutations } from "vuex";
    import * as lang from "@/assets/js/lang";
    import * as ws from "@/assets/js/socket";
    import * as utils from "@/assets/js/utils";
    export default {
        name: "sidebar",
        computed: {
            ...mapState(["showSidebar"]),
            ota_update: {
                get: function () {
                    return this.$store.state.ota_update;
                },
                set: function (value) {
                    this.$store.state.ota_update = value;
                },
            },
        },
        methods: {
            ...mapMutations(["authState"]),
            lang(key) {
                return lang.getTranslation(key);
            },
            logout() {
                ws.send({
                    endpoint: "/auth/logout",
                    method: 1,
                });
                this.authState(false);
            },
            exportConfig() {
                ws.get("/system/config");
            },
        },
        mounted() {
            ws.receive("/auth/logout", (data) => {
                this.$store.state.isAuthenticated = false;
            });
            ws.receive("/system/config", (data) => {
                if (data.content) {
                    console.log(data.content.config);
                    let blob = utils.string2Blob(
                        JSON.stringify(data.content.config)
                    );
                    let url = URL.createObjectURL(blob);
                    let link = utils.getAnchor(url, "config.json");
                    link.click();
                }
            });
            const $ = (el) => document.querySelectorAll(el);
            $(".dropdown-toggle").forEach((e) =>
                e.addEventListener("click", function (e) {
                    $(".dropdown-toggle").forEach((el) => {
                        if (el != e.currentTarget) {
                            el.classList.add("collapsed");
                            el.setAttribute("aria-expanded", false);
                            let target = el.getAttribute("href");
                            let $target = $(target)[0];
                            new bootstrap.Collapse($target, {
                                toggle: false,
                            }).hide();
                        }
                    });
                })
            );
        },
    };
</script>