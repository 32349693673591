<template>
    <div class="d-flex justify-content-between align-items-center">
        <a :class="['sidebar-toggle', showSidebar ? 'active':'']" @click="toggleSidebar()">
            <span></span>
            <span></span>
            <span></span>
        </a>
        <button type="button" class="btn btn-sm btn-light" data-bs-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-language"></i> {{selected.name}}
        </button>
        <ul class="dropdown-menu dropdown-menu-end">
            <li v-for="lang in langs" :key="lang.code">
                <button class="dropdown-item" @click="select(lang.code)" type="button">{{ lang.name }}</button></li>
        </ul>
    </div>
</template>
<script>
    import {
        mapMutations,
        mapState
    } from 'vuex';
    import * as lang from '@/assets/js/lang';

    export default {
        name: 'navbar',
        computed: {
            ...mapState(['showSidebar']),
            selected() {
                return lang.installedLangs.filter(e => e.code == lang.locale)[0];
            },
            langCode() {
                return lang.locale;
            },
            langs() {
                return lang.installedLangs;
            }
        },
        methods: {
            ...mapMutations(['toggleSidebar']),
            lang(key) {
                return lang.getTranslation(key);
            },
            select(code) {
                if (lang.locale != code) {
                    lang.setLocale(code);
                    console.log(lang.locale);
                    this.$store.state.ready = false;
                    setTimeout(() => {
                        this.$store.state.ready = true;
                    }, 1000);
                }
            }
        },
    }
</script>