<template>
    <div v-if="ready" class="h-100">
        <div v-if="!isAuthenticated" class="h-100">
            <login />
            <div v-if="message">
                <notification v-if="message.active" :message="message" />
            </div>
        </div>
        <div v-else>
            <sidebar />
            <div :class="['content',showSidebar ? 'active':'']" id="content">
                <div class="container-fluid">
                    <navbar />
                    <div class="container-fluid pt-4">
                        <router-view />
                    </div><!-- /.container-fluid.pt-4 -->

                </div><!-- /.container -->
                <div class="version">
                    {{version}}
                </div>
                <div v-if="!connected" class="fs-cover"></div>
                <div v-if="message">
                    <notification v-if="message.active" :message="message" />
                </div>
            </div><!-- /.content -->
        </div>

        <exportpopup />
        <updatepopup />
        <configpopup />
    </div>
    <loading v-else />
</template>

<script>
    import {
        mapState,
        mapMutations
    } from 'vuex';
    // import components

    import navbar from '@/components/navbar.vue';
    import sidebar from '@/components/sidebar.vue';
    import notification from '@/components/notification.vue';
    import loading from '@/components/loading.vue';
    import exportpopup from '@/components/popups/export.vue';
    import updatepopup from '@/components/popups/otaupdate.vue';
    import configpopup from '@/components/popups/config.vue';

    import login from '@/views/Login.vue';

    import * as ws from '@/assets/js/socket';
    import * as lang from '@/assets/js/lang';
    import * as db from '@/assets/js/db';
    import config from '@/assets/js/config';
    import * as utils from '@/assets/js/utils';
    import * as measure from '@/assets/js/measure';
    import moment from 'moment';

    moment.locale(lang.locale);

    export default {
        name: 'app',
        components: {
            navbar,
            sidebar,
            notification,
            loading,
            login,
            exportpopup,
            updatepopup,
            configpopup

        },
        data() {
            return {
                connectionCheckTimer: null,
                connected:false
            }
        },
        computed: {
            ...mapState(['showSidebar', 'ready', 'message', 'isAuthenticated', 'version'])
        },
        methods: {
            ...mapMutations(['createConnection', 'readyState', 'authState', 'storeMeasure', 'storeSensorUnit',
                'loadSensorsUnits'
            ]),
            lang(key) {
                return lang.getTranslation(key);
            }
        },
        created: async function () {
            window.onbeforeunload = (e) => {
                console.log(this.$router.currentRoute.value.name);
                if (this.isAuthenticated && (this.$router.currentRoute.value.name == 'statistics' ||
                        this.$router.currentRoute.value.name == 'overview')) {
                    let message = this.lang('messages.exit_alert');
                    console.log(message)
                    e.returnValue = message;
                    return message;
                }
                return null;
            }
            window.addEventListener('ws.reconnect', (e) => {
                this.message.active = false;
                this.message.content = 'Conexión Establecida';
                this.message.icon = 'chain';
                this.message.level = 'success';
                this.message.active = true;
                this.connected = true;
            });
            window.addEventListener('ws.error', (e) => {
                this.message.active = false;
                this.message.content = 'Conexión Perdida';
                this.message.icon = 'chain-broken';
                this.message.level = 'danger';
                this.message.active = true;
                this.connected = false;
                // this.authState(false);
                clearInterval(this.connectionCheckTimer);
                this.connectionCheckTimer = null;
            });
            window.addEventListener('ws.open', (e) => {
                this.message.active = false;
                this.message.content = 'Conexión Establecida';
                this.message.icon = 'chain';
                this.message.level = 'success';
                this.message.active = true;
                this.connected = true;
                clearInterval(this.connectionCheckTimer);
                this.connectionCheckTimer = setInterval(() => {
                    ws.send('ping', true)
                }, 10000);
            });

            clearInterval(this.connectionCheckTimer);
            this.connectionCheckTimer = setInterval(() => {
                ws.send('ping', true)
            }, 10000);

            await db.init({ // convert to promise
                name: 'devward_db',
                tables: [{
                    name: 'measurements',
                    fields: [
                        'date',
                        'index',
                        ...db.sensors
                    ]
                }, {
                    name: 'hour_avg',
                    fields: [
                        'date',
                        'index',
                        ...db.sensors
                    ]
                }, {
                    name: 'day_avg',
                    fields: [
                        'date',
                        'index',
                        ...db.sensors
                    ]
                }, {
                    name: 'week_avg',
                    fields: [
                        'date',
                        'index',
                        ...db.sensors
                    ]
                }, {
                    name: 'month_avg',
                    fields: [
                        'date',
                        'index',
                        ...db.sensors
                    ]
                }, {
                    name: 'year_avg',
                    fields: [
                        'date',
                        'index',
                        ...db.sensors
                    ]
                }]
            });
            let configData = config.load();
            lang.setLocale(configData.locale);
            await lang.load();
            console.log('langs loaded');
            // define request auth middleware
            ws.middleware((data) => {
                if (!data.success) {
                    if (data.error_code == '1007_3') {
                        this.authState(false);
                    }
                    return false;
                }
                return true;
            })

            // handle statistics responses, defined here due the component lifecycle problem
            let table = db.table('measurements'); //get measurements table

            let lowerKey = moment().subtract(1, 'days').format();
            let upperKey = moment().format();

            let storedData = await table.getRange('date', IDBKeyRange.bound(lowerKey, upperKey));
            this.$store.state.measurements = storedData;
            this.loadSensorsUnits();
            let hour_measures = [];
            let last_hour = null;

            ws.receive('/statistics', (result) => {

                this.$store.state.overview = result.content;
                let data = {
                    index: moment().format('YYYY-MM-DD HH:mm:ss'),
                    date: moment().format('YYYY-MM-DD[T]HH:mm:ss'),
                };
                for (let measure of result.content.sensors) {
                    let key = measure.sensor;
                    data[key] = parseFloat(measure.value.toFixed(2));
                    this.storeSensorUnit({
                        key,
                        unit: measure.unit
                    })
                }
                table.insert(data);
                this.storeMeasure(data);
                this.$store.state.measurements = this.$store.state.measurements.filter(e => moment(e
                    .index) > moment().subtract(1, 'days'));

                // store hour average data 

                let hour_step = ':' + utils.addZero(Math.floor(4 * (new Date(Date.now()).getMinutes() /
                    60)) * 15);
                let hour = moment().format('YYYY-MM-DD[T]HH') + hour_step;
                if (last_hour == null) {
                    last_hour = hour;
                }

                if (last_hour == hour) {
                    hour_measures.push(data);
                } else {
                    last_hour = hour;
                    hour_measures = [];
                }

                measure.store('hour_avg', 'index', hour, hour_measures);

                // save day measures
                let hour_format = 'YYYY-MM-DD[T]HH[:00:00]';
                db.table('hour_avg').getRange('index', IDBKeyRange.bound(
                    moment().format(hour_format),
                    moment().add(1, 'hours').format(hour_format)
                )).then(data => {
                    measure.store('day_avg', 'index', moment().format(hour_format), data)
                });

                // save week measures
                let day_format = 'YYYY-MM-DD';
                db.table('day_avg').getRange('index', IDBKeyRange.bound(
                    moment().format(day_format),
                    moment().add(1, 'days').format(day_format)
                )).then(data => {
                    measure.store('week_avg', 'index', moment().format(day_format), data)
                });

                // save month measures
                let week_format = 'YYYY-WW';
                db.table('week_avg').getRange('index', IDBKeyRange.bound(
                    moment().startOf('week').format(day_format),
                    moment().add(1, 'weeks').startOf('week').format(day_format)
                )).then(data => {
                    measure.store('month_avg', 'index', moment().format(week_format), data)
                });

                // save year measures
                let month_format = 'YYYY-MM';
                db.table('month_avg').getRange('index', IDBKeyRange.bound(
                    moment().format(week_format),
                    moment().add(1, 'month').format(week_format)
                )).then(data => {
                    measure.store('year_avg', 'index', moment().format(month_format), data)
                });
            });
            this.readyState(true);
        },
    }
</script>

<style>
    @import url('assets/css/bootstrap.min.css');
    @import url('assets/css/daterangepicker.css');
    @import url('assets/css/fontawesome.min.css');
    @import url('assets/css/select2.min.css');
    @import url('assets/css/app.css');

    .notification {
        position: fixed;
        bottom: 0;
        right: 0;
    }

    #app {
        height: 100%;
    }
</style>