<template>
    <div class="container-fluid h-100">
        <div class="row h-100 align-items-center justify-content-center">
            <div class="col-12 col-md-4">
                <div class="text-center">
                    <img src="@/assets/img/icon_sm.png" class="logo" alt="icon">
                </div>
                <div class="d-flex flex-column">
                    <label for="" class="text-left">{{ lang('labels.server_address') }}</label>
                    <div class="input-group mb-3">
                        <input type="text" @change="connect" v-model="host" placeholder="192.168.1.1"
                            class="form-control">
                        <span :class="['input-group-text', ws_status == 'chain'? 'text-success' : 'text-danger']"
                            :title="ws_status_summary" id="basic-addon1"><i :class="['fa','fa-'+ws_status]"></i></span>
                    </div>
                </div>
                <div class="d-flex flex-column">
                    <label for="" class="text-left">{{ lang('labels.password') }}</label>
                    <div class="input-group mb-3">
                        <input :type="showPassword ? 'text':'password'" v-model="password" @change="login"
                            @keyup="loginOnSubmit" class="form-control m-0">
                        <div class="input-group-text" @click="showPassword = !showPassword"><i
                                :class="['fa', showPassword ? 'fa-eye-slash':'fa-eye']"></i></div>
                    </div>
                    <span
                        :class="['text-danger','mb-3',password_validation == '' ? 'd-none':'']">{{ password_validation }}</span>
                </div>
                <div class="text-center">
                    <button @click="login" class="btn btn-outline-dark">{{ lang('buttons.get_in') }}</button>
                </div>
            </div>
            <div class="col-12 d-flex justify-content-center">
                <button type="button" class="btn btn-sm btn-light" data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="fa fa-language"></i> {{selected.name}}
                </button>
                <ul class="dropdown-menu dropdown-menu-end">
                    <li v-for="lang in langs" :key="lang.code">
                        <button class="dropdown-item" @click="select(lang.code)" type="button">{{ lang.name }}</button>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
    import * as lang from '@/assets/js/lang';
    import * as ws from '@/assets/js/socket';
    import * as cookie from '@/assets/js/cookie';
    import {
        mapMutations,
        mapState
    } from 'vuex';

    export default {
        name: 'login',
        data() {
            return {
                token: 'test',
                password: '',
                ws_status: 'hourglass',
                ws_status_summary: '',
                password_validation: '',
                password_validation_timeout: null,
                showPassword: false,
            }
        },
        computed: {
            host: {
                get: function () {
                    return this.$store.state.host;
                },
                set: function (value) {
                    this.$store.state.host = value;
                }
            },
            selected() {
                return lang.installedLangs.filter(e => e.code == lang.locale)[0];
            },
            langCode() {
                return lang.locale;
            },
            langs() {
                return lang.installedLangs;
            }
        },
        methods: {
            ...mapMutations(['authState', 'setRemoteHost']),
            lang(key) {
                return lang.getTranslation(key);
            },
            connect() {
                ws.connect(`ws://${this.host}/ws`);
                this.setRemoteHost(this.host);
            },
            login() {
                ws.send({
                    endpoint: '/auth/login',
                    totp: this.password
                })
            },
            loginOnSubmit(e) {
                if (e.keyCode === 13 || e.which === 13) {
                    e.preventDefault();
                    this.login();
                }
            },
            select(code) {
                if (lang.locale != code) {
                    lang.setLocale(code);
                    console.log(lang.locale);
                    this.$store.state.ready = false;
                    setTimeout(() => {
                        this.$store.state.ready = true;
                    }, 1000);
                }
            }
        },
        created() {
            this.ws_status_summary = this.lang('labels.conn_state_waiting');
            window.addEventListener('ws.open', (e) => {
                this.ws_status = 'chain';
                this.ws_status_summary = this.lang('labels.conn_state_connected');
                let token = cookie.get('token');
                if (token != '') {
                    ws.send({
                        endpoint: '/auth/login'
                    })
                }
            });
            window.addEventListener('ws.error', (e) => {
                this.ws_status = 'chain-broken';
                this.ws_status_summary = this.lang('labels.conn_state_error');
            });

            ws.receive({
                endpoint: '/auth/login',
                method: 0,
                success: (data) => {
                    this.authState(true);
                    if (data.token) {
                        cookie.set('token', data.token);

                    }
                },
                error: (e) => {
                    if (e.error_code == '1007_5') {
                        this.password_validation = this.lang('errors.invalid_auth_credentials')
                        clearTimeout(this.password_validation_timeout);
                        this.password_validation_timeout = setTimeout(() => {
                            this.password_validation = ''
                        }, 3000);
                    }
                }
            });
            if (this.host != '') {
                this.connect();
            }
        }
    }
</script>