<template>
    <div :class="['load-indicator','active','text-'+message.level,message.animation,]">
        <p class="m-0 px-2">{{ message.content }}</p>
        <i :class="['fa','fa-'+message.icon]"></i>
    </div>
</template>
<script>
    import {
        mapMutations
    } from 'vuex'
    export default {
        name: 'notification',
        props: {
            message: Object
        },
        methods: {
            ...mapMutations(['hideNotification'])
        },
        created() {
            setTimeout(() => {
                this.hideNotification();
            }, this.message.duration);
        }
    }
</script>