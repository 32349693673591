const load = () => {
    let locale = localStorage.getItem('locale') || 'en';
    return {
        locale
    }
}
const set = (locale) => {
    localStorage.setItem('locale',locale);
}

export default {
    load,
    set
};