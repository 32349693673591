const EMPTY_STRING = '';
const CONTENT_MATCH = /\(.*?(?:(?!\\u).)*/u;
const TYPE_MATCH = /^.*?(?= )/;
const DEFAULT_INVALID_SYNTAX_MESSAGE = "Invalid syntax in received message";
const DEFAULT_COLOR = 'default';

const DEFAULT_END = '[0m';
const DEFAULT_UNICODE_END = '\u001b[0m';
const DEFAULT_UNICODE_ERROR = '\u001b[0;31mE';
const DEFAULT_UNICODE_SCAPED_ERROR = '\\u001b[0;31mE';
const DEFAULT_UNICODE_INFO = '\u001b[0;32mI';
const DEFAULT_UNICODE_SCAPED_INFO = '\\u001b[0;32mI';
const DEFAULT_UNICODE_WARNING = '\u001b[0;33mW';
const DEFAULT_UNICODE_SCAPED_WARNING = '\\u001b[0;33mW';


export const addZero = (v) => {
    if (typeof (v) === 'number') {
        if (v < 10) {
            return '0' + v;
        }
        return v;
    }
    return v;
};

export const average = (values, round) => {
    let avg = values.reduce((p, c) => p + c, 0) / values.length;
    return parseFloat(avg.toFixed(round));
};

/**
 * Format diagnostic file
 * @date 2022-03-20
 * @param string content
 * @returns {any}
 */
export const formatDiagnostic = (content) => {
    let lines = content.split('\n');
    console.log(lines);
    const formatLine = (line) => {
        let template = '<p class="m-0 text-{color}">[{type}] - {content}</p>';

        let color = 'dark';
        let type = 'log';
        if(line[0] === 'E'){
            color = 'danger'
            type = 'error'
        }else if(line[0] == 'W'){
            color = 'warning'
            type = 'warn'
        }else if(line[0] == 'I'){
            color = 'success'
            type = 'info'
        }
        let content = line.substring(2)

        return format(template, {
            color,
            type,
            content
        })
    }

    for(let key in lines){
        lines[key] = formatLine(lines[key])
    }
    return lines.join('')
};


/**
 * deprecated
 * @date 2022-03-20
 * @param {any} message
 * @returns {any}
 */
export const formatMessage = (message) => {
    let content, type, color;

    content = message.match(CONTENT_MATCH);
    if (content == null || content == EMPTY_STRING) {
        content = message;
    }
    type = message.match(TYPE_MATCH);
    content = content.toString();
    if (content.substring(content.length - 3, content.length) == DEFAULT_END) {
        content = content.replace(DEFAULT_UNICODE_END, EMPTY_STRING);
    }
    //color = TYPES_MAP.type;
    if (type == DEFAULT_UNICODE_ERROR || type == DEFAULT_UNICODE_SCAPED_ERROR) {
        color = 'danger';
        type = 'error';
    } else if (type == DEFAULT_UNICODE_INFO || type == DEFAULT_UNICODE_SCAPED_INFO) {
        color = 'success';
        type = 'info';
    } else if (type == DEFAULT_UNICODE_WARNING || type == DEFAULT_UNICODE_SCAPED_WARNING) {
        color = 'warning';
        type = 'warning';
    } else {
        type = 'log';
        color = DEFAULT_COLOR;
    }
    let result = '<span class="text-' + color + '">[' + type + '] ' + content + '</span>';
    content = undefined;
    color = undefined;
    type = undefined;
    return result;
};

/**
 * deprecated
 * @date 2022-03-20
 * @param {any} content
 * @returns {any}
 */
export const formatFile = (content) => {
    let lines = content.split('\n');
    for (let i in lines) {
        lines[i] = formatMessage(lines[i]);
    }
    return lines.join('<br>');
};


export const b64toBlob = function (b64Data, contentType, sliceSize) {
    // function taken from http://stackoverflow.com/a/16245768/2591950
    // author Jeremy Banks http://stackoverflow.com/users/1114/jeremy-banks
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i = i + 1) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, {
        type: contentType
    });
};

export const templates = {
    excel: '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><meta name=ProgId content=Excel.Sheet> <meta name=Generator content="Microsoft Excel 11"><meta http-equiv="Content-Type" content="text/html; charset=UTF-8"><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>'
};

/**
 * Convert a string to Base64.
 */
export const base64 = function (s) {
    return btoa(unescape(encodeURIComponent(s)));
};

export const format = function (s, context) {
    return s.replace(new RegExp("{(\\w+)}", "g"), function (m, p) {
        return context[p] || "{" + p + "}";
    });
};
export const string2Blob = function (str) {
    return new Blob([string2ArrayBuffer(str)], {
        type: "application/octet-stream"
    })
};
export const string2ArrayBuffer = function (s) {
    let buf = new ArrayBuffer(s.length);
    let view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) {
        view[i] = s.charCodeAt(i) & 0xFF;
    }
    return buf;
};
export const getAnchor = (url, filename) => {
    let link = document.createElement('a');
    link.setAttribute('download', filename);
    link.setAttribute('href', url);
    link.addEventListener('click', (e) => {
        const timeout = setTimeout(() => {
            console.log('url revoked');
            URL.revokeObjectURL(url);
            clearTimeout(timeout);
        }, 10000);
    });
    return link;
}