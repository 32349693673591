<template>
    <div class="modal fade" id="updateModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
        aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div v-if="ota_update == 1">
                        <h3 class="text-center" style="font-size: 70px;"><i class="fa fa-refresh a-rotate"></i>
                        </h3>
                        <div class="text-center a-dotted">{{ lang('messages.checking_for_update') }}</div>
                    </div>
                    <div v-else-if="ota_update == 2">
                        <h3 class="text-center" style="font-size: 70px;"><i class="fa fa-question-circle"></i>
                        </h3>
                        <div class="text-center">{{ lang('messages.update_system') }}</div>
                    </div>
                    <div v-else-if="ota_update == 3">
                        <h3 class="text-center" style="font-size: 70px;"><i class="fa fa-refresh a-rotate"></i>
                        </h3>
                        <div class="text-center a-dotted">{{ lang('messages.updating') }}</div>
                    </div>
                    <div v-else-if="ota_update == 4">
                        <h3 class="text-center text-success" style="font-size: 70px;"><i class="fa fa-check"></i>
                        </h3>
                        <div class="text-center">{{ lang('messages.update_success') }}</div>
                    </div>
                    <div v-else-if="ota_update == 5">
                        <h3 class="text-center text-danger" style="font-size: 70px;"><i class="fa fa-times"></i>
                        </h3>
                        <div class="text-center">{{ lang('messages.update_fail') }}</div>
                    </div>
                </div>
                <div
                    :class="['modal-footer', 'd-flex', ota_update == 4 || ota_update == 5 ? 'justify-content-center':'justify-content-between']">
                    <button v-if="ota_update != 4 && ota_update != 5" type="button" class="btn btn-secondary"
                        data-bs-dismiss="modal" @click="cancelUpdate"
                        :disabled="ota_update < 2">{{ lang('buttons.cancel') }}</button>
                    <button v-if="ota_update == 2" type="button" class="btn btn-primary"
                        @click="startUpdate">{{ lang('buttons.continue') }}</button>
                    <button v-else-if="ota_update == 4" type="button" class="btn btn-success" @click="restartDevice"
                        data-bs-dismiss="modal">{{ lang('buttons.accept') }}</button>
                    <button v-else-if="ota_update == 5" type="button" class="btn btn-danger" data-bs-dismiss="modal"
                        @click="ota_update = 2">{{ lang('buttons.accept') }}</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import * as lang from '@/assets/js/lang';
    import * as ws from '@/assets/js/socket';

    import {
        mapMutations
    } from 'vuex';

    export default {
        computed: {
            ota_update: {
                get: function () {
                    return this.$store.state.ota_update;
                },
                set: function (value) {
                    this.$store.state.ota_update = value;
                }
            }
        },
        methods: {
            ...mapMutations(['readyState', 'authState']),
            lang(key) {
                return lang.getTranslation(key);
            },
            cancelUpdate() {
                if (this.ota_update > 2) {
                    ws.post('/system/ota_update', {
                        ota_update: false
                    })
                }
            },
            startUpdate() {
                ws.post('/system/ota_update', {
                    ota_update: true
                });
            },
            restartDevice() {
                this.$store.state.ready = false;
                setTimeout(() => {
                    this.$store.state.ready = true;
                }, 2000);
                this.authState(false);
            }
        },
        created() {
            ws.receive({
                    endpoint: '/system/ota_update',
                    method: 1,
                    success: (data) => {
                        console.log(data);
                        if (data.content) {
                            this.ota_update = data.content.ota_update
                        } else {
                            this.ota_update = 3;
                            setTimeout(() => {
                                this.ota_update = 4;
                            }, 5000);
                        }
                    },
                    error: (data) => {
                        this.ota_update = 5;
                    }
                })
            }
        }
</script>