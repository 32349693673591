import {
    get
} from 'axios'
import config from '@/assets/js/config';
import moment from 'moment';

var languages = {};
export var installedLangs = [];
export var locale = 'es';
/**
 * return translation for "key"
 * @date 2022-02-26
 * @param {string} key
 * @returns {string}
 */
export const getTranslation = (key) => {
    let arr = key.split('.');
    let result = languages[locale || 'es'];
    for (let field of arr) {
        result = result[field];
    }
    return result;
};

/**
 * Set localization code
 * @date 2022-03-08
 * @param {any} code
 */

export const setLocale = (code) => {
    locale = code;
    moment.locale(locale);
    config.set(locale);
}


export const load = async function () {
    let langs = await get('/lang/langs.json');
    let list = langs.data.languageFiles;
    for (let file of list) {
        let content = await get(`/lang/${file}`);
        let data = content.data;
        let code = data.code;
        let name = data.name;
        languages[code] = data;

        installedLangs.push({
            code,
            name,
        })
    }
}