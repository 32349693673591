export const set = (name, value, expiration) => {
    let date = new Date(Date.now() + 1000 * 60 * 60 * 24 * expiration);
    let expires = 'expires=' + date.toUTCString();
    document.cookie = name + '=' + value + ';' + expires + ';path=/;SameSite=Lax';
}

export const remove = (name) => {
    document.cookie = name + "=;path=/;";
}

export const get = (name) => {
    name = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export const check = (name) => {
    let result = get(name);
    return result != "";
}